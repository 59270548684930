.App {
	text-align: center;
	background-color: #121212;
	width: 100%;
	min-height: 100vh;
	color: white;
}

body {
	font-family: 'Open Sans', sans-serif;
	padding: 0;
	margin: 0;
}

nav {
	padding: 1rem 0;
	width: 100%;
	margin: 0;
	border-bottom: 1px solid grey;
	display: grid;
	place-items: center;
}

nav h1 {
	margin: 0;
	color: white;
	font-size: 45px;
}
.game {
	width: 100%;
	display: flex;
	align-items: center;
	padding-top: 50px;
	flex-direction: column;
}

.board {
	width: 450px;
	height: 450px;
	display: flex;
	flex-direction: column;
}

.row {
	flex: 33%;
	display: flex;
	flex-direction: row;
	margin: 5px;
	align-items: center;
}

.letter {
	flex: 33%;
	height: 100%;
	border: 1px solid grey;
	margin: 5px;
	display: grid;
	place-items: center;
	font-size: 30px;
	font-weight: bolder;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
	border-radius: 3px;
}

.correct {
	background-color: #528d4e;
}

.almost {
	background-color: #b49f39;
}

.error {
	background-color: #3a393c;
}

.keyboard {
	width: 700px;
	height: 300px;
	margin-top: 60px;
}

.line1 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	display: flex;
	justify-content: center;
	margin: 5px;
}
.line2 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 5px;
}
.line3 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 5px;
}

.key {
	width: 50px;
	height: 70px;
	margin: 5px;
	border-radius: 4px;
	display: grid;
	place-items: center;
	font-size: 20px;
	background-color: grey;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
	cursor: pointer;
}

.big {
	width: 100px;
}
.disabled {
	background-color: #3a393c;
}

.gameOver {
	padding-top: 30px;
}

@media screen and (max-width: 767px) {
	.game {
		padding-top: 20px;
	}
	.board,
	.keyboard {
		width: 370px;
	}
	.board {
		height: 295px;
	}
	.keyboard {
		margin-top: 20px;
	}
	.letter {
		font-size: 22px;
	}
	.key {
		margin: 2px;
		font-size: 14px;
	}
}
